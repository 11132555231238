import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const NeustadtNord: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.NeustadtNord,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Neustadt-Nord"
          d="M493,558.6l19,1.4l22-16l22-2l34,22l-22,24l-30-4c-15.5,2.2-59.9,11.7-54.8,61.4l-39.2-1.3   c-1-13.2,5.2-31.4,8-44.1c5-7,29.7-34.7,35.8-41.7L493,558.6z"
        />
        <g className={styles.districtName} ref={ref}>
          <text
            transform="translate(486.578 576.717)"
            textRendering="geometricPrecision"
          >
            Neustadt-Nord
          </text>
        </g>
      </g>
    </>
  );
});
